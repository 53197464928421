// HEADERS
export const channel_header = 'x-client-channel'; // mobile or web
export const locale_header = 'x-market-locale'; //fr_FR or es_ES
export const email_hidden_header = 'x-email-hidden'; // SHA1
export const email_strong_header = 'x-email-strong'; // SHA256
export const email_stronger_header = 'x-email-stronger'; // HMAC-SHA256
export const consent_vendors_header = 'x-con-v';
export const consent_string_header = 'x-con-s';
export const consent_id_header = 'x-con-id';
export const consent_exempt_id_header = 'x-con-ex';
export const env_header = 'x-api-env';
export const client_app_id_header = 'x-client-app-id'; // front-web
export const device_os_version_header = 'x-device-os-version'; // Android Samsung xv25
export const device_class_header = 'x-device-class'; // [smartphone|tablet]
export const app_version_header = 'x-app-version'; // 20201002-unknown [date build]-[hashgit]
export const bff_key_header = 'x-bff-key';
export const navigation_previous_header = 'x-nav-previous-page';
export const navigation_current_header = 'x-nav-current-path';
export const navigation_page_params = 'x-nav-page-params';
export const visitor_type_header = 'x-visitor-type';
export const events_push_header = 'x-events-push';
export const events_click_header = 'x-events-click';
export const trace_id_header = 'x-trace-id';
export const attribution_medium_header = 'x-attribution-medium';
export const attribution_source_header = 'x-attribution-source';
export const attribution_campaign_header = 'x-attribution-campaign';
export const attribution_content_header = 'x-attribution-content';
export const attribution_term_header = 'x-attribution-term';
export const attribution_referrer_header = 'x-attribution-referrer';
export const attribution_clid_header = 'x-attribution-clid';
export const attribution_prex_header = 'x-attribution-prex';
export const attribution_id_compteur_header = 'x-attribution-id-compteur';
export const attribution_effi_id_header = 'x-attribution-effi-id';
export const attribution_effi_id2_header = 'x-attribution-effi-id2';
export const attribution_gclid_header = 'x-attribution-gclid';

// QUERY PARAMS
export const wiz_medium = 'wiz_medium';
export const wiz_source = 'wiz_source';
export const wiz_campaign = 'wiz_campaign';
export const wiz_term = 'wiz_term';
export const wiz_content = 'wiz_content';
export const wiz_clid = 'wiz_clid';
export const gclid = 'gclid';
export const prex = 'prex';
export const id_compteur = 'id_compteur';
export const effi_id = 'effi_id';
export const effi_id2 = 'effi_id2';
export const post_back_id = 'postbackid';
