/* tslint:disable */
/* eslint-disable */
/**
 * Invictus
 * Invictus Backend API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { LegacyTokenRequest } from '../model';
// @ts-ignore
import { LogoutBackRequest } from '../model';
// @ts-ignore
import { LogoutFrontResponse } from '../model';
// @ts-ignore
import { OutputErrorMessage } from '../model';
// @ts-ignore
import { RefreshTokenRequest } from '../model';
// @ts-ignore
import { TokenRequest } from '../model';
// @ts-ignore
import { UserAccountFound } from '../model';
/**
 * AuthenticationControllerApi - axios parameter creator
 * @export
 */
export const AuthenticationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} xBffKey bff key header
         * @param {TokenRequest} tokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticate: async (xBffKey: string, tokenRequest: TokenRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            if (xBffKey === null || xBffKey === undefined) {
                throw new RequiredError('xBffKey','Required parameter xBffKey was null or undefined when calling authenticate.');
            }
            // verify required parameter 'tokenRequest' is not null or undefined
            if (tokenRequest === null || tokenRequest === undefined) {
                throw new RequiredError('tokenRequest','Required parameter tokenRequest was null or undefined when calling authenticate.');
            }
            const localVarPath = `/api/v1/authenticate/apply`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey !== undefined && xBffKey !== null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof tokenRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(tokenRequest !== undefined ? tokenRequest : {})
                : (tokenRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Provided for not authenticated user to check obsolete mobile versions
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkVersion: async (xBffKey: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            if (xBffKey === null || xBffKey === undefined) {
                throw new RequiredError('xBffKey','Required parameter xBffKey was null or undefined when calling checkVersion.');
            }
            const localVarPath = `/api/v1/check-version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey !== undefined && xBffKey !== null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} xBffKey bff key header
         * @param {LogoutBackRequest} logoutBackRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutBack: async (xBffKey: string, logoutBackRequest: LogoutBackRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            if (xBffKey === null || xBffKey === undefined) {
                throw new RequiredError('xBffKey','Required parameter xBffKey was null or undefined when calling logoutBack.');
            }
            // verify required parameter 'logoutBackRequest' is not null or undefined
            if (logoutBackRequest === null || logoutBackRequest === undefined) {
                throw new RequiredError('logoutBackRequest','Required parameter logoutBackRequest was null or undefined when calling logoutBack.');
            }
            const localVarPath = `/api/v1/logout/back`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey !== undefined && xBffKey !== null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof logoutBackRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(logoutBackRequest !== undefined ? logoutBackRequest : {})
                : (logoutBackRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} redirectUri 
         * @param {string} xBffKey bff key header
         * @param {string} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutFront: async (redirectUri: string, xBffKey: string, state?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'redirectUri' is not null or undefined
            if (redirectUri === null || redirectUri === undefined) {
                throw new RequiredError('redirectUri','Required parameter redirectUri was null or undefined when calling logoutFront.');
            }
            // verify required parameter 'xBffKey' is not null or undefined
            if (xBffKey === null || xBffKey === undefined) {
                throw new RequiredError('xBffKey','Required parameter xBffKey was null or undefined when calling logoutFront.');
            }
            const localVarPath = `/api/v1/logout/front`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (redirectUri !== undefined) {
                localVarQueryParameter['redirectUri'] = redirectUri;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (xBffKey !== undefined && xBffKey !== null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} xBffKey bff key header
         * @param {RefreshTokenRequest} refreshTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reauthenticate: async (xBffKey: string, refreshTokenRequest: RefreshTokenRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            if (xBffKey === null || xBffKey === undefined) {
                throw new RequiredError('xBffKey','Required parameter xBffKey was null or undefined when calling reauthenticate.');
            }
            // verify required parameter 'refreshTokenRequest' is not null or undefined
            if (refreshTokenRequest === null || refreshTokenRequest === undefined) {
                throw new RequiredError('refreshTokenRequest','Required parameter refreshTokenRequest was null or undefined when calling reauthenticate.');
            }
            const localVarPath = `/api/v1/reauthenticate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey !== undefined && xBffKey !== null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof refreshTokenRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(refreshTokenRequest !== undefined ? refreshTokenRequest : {})
                : (refreshTokenRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         *  To be used only in the OUI -> IVTS mobile update : converts the given legacy (Keycloack),             retrieves the corresponding SNCF Connect token, and performs a re-authentication with it.             
         * @summary Retrieves a SNCF token from a legacy (Keycloack) token and reauthenticates
         * @param {string} xBffKey bff key header
         * @param {LegacyTokenRequest} legacyTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reauthenticateWithLegacyToken: async (xBffKey: string, legacyTokenRequest: LegacyTokenRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            if (xBffKey === null || xBffKey === undefined) {
                throw new RequiredError('xBffKey','Required parameter xBffKey was null or undefined when calling reauthenticateWithLegacyToken.');
            }
            // verify required parameter 'legacyTokenRequest' is not null or undefined
            if (legacyTokenRequest === null || legacyTokenRequest === undefined) {
                throw new RequiredError('legacyTokenRequest','Required parameter legacyTokenRequest was null or undefined when calling reauthenticateWithLegacyToken.');
            }
            const localVarPath = `/api/v1/reauthenticate-with-legacy-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey !== undefined && xBffKey !== null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof legacyTokenRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(legacyTokenRequest !== undefined ? legacyTokenRequest : {})
                : (legacyTokenRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webLogoutBack: async (xBffKey: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            if (xBffKey === null || xBffKey === undefined) {
                throw new RequiredError('xBffKey','Required parameter xBffKey was null or undefined when calling webLogoutBack.');
            }
            const localVarPath = `/api/v1/web-refresh/logout/back`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey !== undefined && xBffKey !== null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webReauthenticate: async (xBffKey: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            if (xBffKey === null || xBffKey === undefined) {
                throw new RequiredError('xBffKey','Required parameter xBffKey was null or undefined when calling webReauthenticate.');
            }
            const localVarPath = `/api/v1/web-refresh/reauthenticate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey !== undefined && xBffKey !== null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationControllerApi - functional programming interface
 * @export
 */
export const AuthenticationControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} xBffKey bff key header
         * @param {TokenRequest} tokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticate(xBffKey: string, tokenRequest: TokenRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAccountFound>> {
            const localVarAxiosArgs = await AuthenticationControllerApiAxiosParamCreator(configuration).authenticate(xBffKey, tokenRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Provided for not authenticated user to check obsolete mobile versions
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkVersion(xBffKey: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AuthenticationControllerApiAxiosParamCreator(configuration).checkVersion(xBffKey, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} xBffKey bff key header
         * @param {LogoutBackRequest} logoutBackRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logoutBack(xBffKey: string, logoutBackRequest: LogoutBackRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await AuthenticationControllerApiAxiosParamCreator(configuration).logoutBack(xBffKey, logoutBackRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} redirectUri 
         * @param {string} xBffKey bff key header
         * @param {string} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logoutFront(redirectUri: string, xBffKey: string, state?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogoutFrontResponse>> {
            const localVarAxiosArgs = await AuthenticationControllerApiAxiosParamCreator(configuration).logoutFront(redirectUri, xBffKey, state, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} xBffKey bff key header
         * @param {RefreshTokenRequest} refreshTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reauthenticate(xBffKey: string, refreshTokenRequest: RefreshTokenRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAccountFound>> {
            const localVarAxiosArgs = await AuthenticationControllerApiAxiosParamCreator(configuration).reauthenticate(xBffKey, refreshTokenRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *  To be used only in the OUI -> IVTS mobile update : converts the given legacy (Keycloack),             retrieves the corresponding SNCF Connect token, and performs a re-authentication with it.             
         * @summary Retrieves a SNCF token from a legacy (Keycloack) token and reauthenticates
         * @param {string} xBffKey bff key header
         * @param {LegacyTokenRequest} legacyTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reauthenticateWithLegacyToken(xBffKey: string, legacyTokenRequest: LegacyTokenRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAccountFound>> {
            const localVarAxiosArgs = await AuthenticationControllerApiAxiosParamCreator(configuration).reauthenticateWithLegacyToken(xBffKey, legacyTokenRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webLogoutBack(xBffKey: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await AuthenticationControllerApiAxiosParamCreator(configuration).webLogoutBack(xBffKey, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webReauthenticate(xBffKey: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAccountFound>> {
            const localVarAxiosArgs = await AuthenticationControllerApiAxiosParamCreator(configuration).webReauthenticate(xBffKey, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AuthenticationControllerApi - factory interface
 * @export
 */
export const AuthenticationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} xBffKey bff key header
         * @param {TokenRequest} tokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticate(xBffKey: string, tokenRequest: TokenRequest, options?: any): AxiosPromise<UserAccountFound> {
            return AuthenticationControllerApiFp(configuration).authenticate(xBffKey, tokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Provided for not authenticated user to check obsolete mobile versions
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkVersion(xBffKey: string, options?: any): AxiosPromise<void> {
            return AuthenticationControllerApiFp(configuration).checkVersion(xBffKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} xBffKey bff key header
         * @param {LogoutBackRequest} logoutBackRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutBack(xBffKey: string, logoutBackRequest: LogoutBackRequest, options?: any): AxiosPromise<object> {
            return AuthenticationControllerApiFp(configuration).logoutBack(xBffKey, logoutBackRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} redirectUri 
         * @param {string} xBffKey bff key header
         * @param {string} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutFront(redirectUri: string, xBffKey: string, state?: string, options?: any): AxiosPromise<LogoutFrontResponse> {
            return AuthenticationControllerApiFp(configuration).logoutFront(redirectUri, xBffKey, state, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} xBffKey bff key header
         * @param {RefreshTokenRequest} refreshTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reauthenticate(xBffKey: string, refreshTokenRequest: RefreshTokenRequest, options?: any): AxiosPromise<UserAccountFound> {
            return AuthenticationControllerApiFp(configuration).reauthenticate(xBffKey, refreshTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *  To be used only in the OUI -> IVTS mobile update : converts the given legacy (Keycloack),             retrieves the corresponding SNCF Connect token, and performs a re-authentication with it.             
         * @summary Retrieves a SNCF token from a legacy (Keycloack) token and reauthenticates
         * @param {string} xBffKey bff key header
         * @param {LegacyTokenRequest} legacyTokenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reauthenticateWithLegacyToken(xBffKey: string, legacyTokenRequest: LegacyTokenRequest, options?: any): AxiosPromise<UserAccountFound> {
            return AuthenticationControllerApiFp(configuration).reauthenticateWithLegacyToken(xBffKey, legacyTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webLogoutBack(xBffKey: string, options?: any): AxiosPromise<object> {
            return AuthenticationControllerApiFp(configuration).webLogoutBack(xBffKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webReauthenticate(xBffKey: string, options?: any): AxiosPromise<UserAccountFound> {
            return AuthenticationControllerApiFp(configuration).webReauthenticate(xBffKey, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationControllerApi - object-oriented interface
 * @export
 * @class AuthenticationControllerApi
 * @extends {BaseAPI}
 */
export class AuthenticationControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} xBffKey bff key header
     * @param {TokenRequest} tokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationControllerApi
     */
    public authenticate(xBffKey: string, tokenRequest: TokenRequest, options?: any) {
        return AuthenticationControllerApiFp(this.configuration).authenticate(xBffKey, tokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Provided for not authenticated user to check obsolete mobile versions
     * @param {string} xBffKey bff key header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationControllerApi
     */
    public checkVersion(xBffKey: string, options?: any) {
        return AuthenticationControllerApiFp(this.configuration).checkVersion(xBffKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} xBffKey bff key header
     * @param {LogoutBackRequest} logoutBackRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationControllerApi
     */
    public logoutBack(xBffKey: string, logoutBackRequest: LogoutBackRequest, options?: any) {
        return AuthenticationControllerApiFp(this.configuration).logoutBack(xBffKey, logoutBackRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} redirectUri 
     * @param {string} xBffKey bff key header
     * @param {string} [state] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationControllerApi
     */
    public logoutFront(redirectUri: string, xBffKey: string, state?: string, options?: any) {
        return AuthenticationControllerApiFp(this.configuration).logoutFront(redirectUri, xBffKey, state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} xBffKey bff key header
     * @param {RefreshTokenRequest} refreshTokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationControllerApi
     */
    public reauthenticate(xBffKey: string, refreshTokenRequest: RefreshTokenRequest, options?: any) {
        return AuthenticationControllerApiFp(this.configuration).reauthenticate(xBffKey, refreshTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  To be used only in the OUI -> IVTS mobile update : converts the given legacy (Keycloack),             retrieves the corresponding SNCF Connect token, and performs a re-authentication with it.             
     * @summary Retrieves a SNCF token from a legacy (Keycloack) token and reauthenticates
     * @param {string} xBffKey bff key header
     * @param {LegacyTokenRequest} legacyTokenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationControllerApi
     */
    public reauthenticateWithLegacyToken(xBffKey: string, legacyTokenRequest: LegacyTokenRequest, options?: any) {
        return AuthenticationControllerApiFp(this.configuration).reauthenticateWithLegacyToken(xBffKey, legacyTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} xBffKey bff key header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationControllerApi
     */
    public webLogoutBack(xBffKey: string, options?: any) {
        return AuthenticationControllerApiFp(this.configuration).webLogoutBack(xBffKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} xBffKey bff key header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationControllerApi
     */
    public webReauthenticate(xBffKey: string, options?: any) {
        return AuthenticationControllerApiFp(this.configuration).webReauthenticate(xBffKey, options).then((request) => request(this.axios, this.basePath));
    }
}
