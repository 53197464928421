import { canUseDom } from './user';

// Permet d'exécuter la fonction ineum d'Instana seulement si elle est définie et éviter un undefined si jamais le script Instana n'est pas chargé.
export const websiteMonitor = (callback: () => void): void => {
  // FIXME: le run validate du web plante si je ne met pas ça. On voit post livraison de Beta
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (canUseDom() && ineum) {
    callback();
  }
  return;
};
