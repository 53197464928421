import { Configuration } from './generated';
import { BASE_PATH, BaseAPI } from './generated/base';
import { UserSdk } from './user';
import { AxiosInstance } from 'axios';
import { addCredentials } from './configuration';

export type AbstractSdkConstructorParameters = ConstructorParameters<typeof AbstractSdk>;

export type ControllerApiConstructor<ControllerApi extends BaseAPI> = new (
  ...args: ConstructorParameters<typeof BaseAPI>
) => ControllerApi;

export abstract class AbstractSdk<ControllerApi extends BaseAPI = BaseAPI> {
  protected readonly api: ControllerApi;

  protected abstract getControllerApiConstructor(): ControllerApiConstructor<ControllerApi>;

  constructor(configuration: Configuration, protected readonly userSdk: UserSdk, axios?: AxiosInstance) {
    addCredentials(configuration);

    const controllerApiConstructor = this.getControllerApiConstructor();
    this.api = new controllerApiConstructor(configuration, BASE_PATH, axios);
    this.userSdk = userSdk;
  }
}
