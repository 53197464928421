import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { UserSdk } from './user';
import { AuthenticationSdk } from './authentication';

export interface IvtsAxiosRequestConfig extends AxiosRequestConfig {
  shouldRefreshTokens: boolean;
}

export interface AugmentedAxiosRequestConfig extends AxiosRequestConfig {
  ivts: IvtsAxiosRequestConfig;
}

const isAugmentedAxiosRequestConfig = (config: unknown): config is AugmentedAxiosRequestConfig =>
  (config as AugmentedAxiosRequestConfig).ivts !== undefined;

const createRefreshTokensIfNeededRequestInterceptor = (userSdk: UserSdk, authenticationSdk: AuthenticationSdk) => {
  return async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    if (isAugmentedAxiosRequestConfig(config) && config.ivts.shouldRefreshTokens && userSdk.isRefreshTokensNeeded()) {
      await authenticationSdk.refreshTokensFromInterceptor();
    }
    return config;
  };
};

const passThroughError = (error: unknown): Promise<unknown> => {
  return Promise.reject(error);
};

export const addAxiosRequestInterceptors = (
  axios: AxiosInstance,
  userSdk: UserSdk,
  authenticationSdk: AuthenticationSdk,
): void => {
  const refreshTokensIfNeededRequestInterceptor = createRefreshTokensIfNeededRequestInterceptor(
    userSdk,
    authenticationSdk,
  );

  axios.interceptors.request.use(refreshTokensIfNeededRequestInterceptor, passThroughError);
};
