import { canUseDom, canUseNavigator, DeviceSettings, User, UserSdk } from './user';
import { Configuration, MetadataContent, TrackingRequest, TrackingResponseSuccess } from './generated';
import { getTrackingDeviceType, getTrackingOs, TrackingSdk } from './tracking';
import axios from 'axios';
import { AuthenticationSdk } from './authentication';
import { addAxiosRequestInterceptors } from './interceptors';
import { UAParser } from 'ua-parser-js';
import { StorageSdk } from './storage';

export * from '../src/generated/model';
export * from '../src/generated/configuration';
export * from './interceptors';
export * from './error';
export * from './authentication';
export * from './user';
export * from './storage';
export * from './tracking';
export * from './userConstants';

declare let API_URL: string;
declare let ENV: string;

declare global {
  interface Window {
    ivtsSdk: MiniSdk;
    ivtsTag: {
      config: {
        clientId: string;
        market: string;
      };
      data: TrackingRequest;
    };
    utag_data: MetadataContent | undefined;
    utag: {
      view: (datalayer: MetadataContent | undefined) => void;
    };
    Didomi: {
      getUserStatus(): {
        consent_string: string;
        vendors: {
          consent: {
            enabled: string[];
          };
        };
      };
      getUserConsentStatusForVendor(vendor: string): boolean;
    };
    didomiEventListeners: {
      event: string;
      listener: () => void;
    }[];
  }
}

export type MiniSdk = {
  trackingSdk: TrackingSdk;
  storageSdk: StorageSdk;
};

export const initMiniSdk = (configuration: Configuration, userSdk: UserSdk): MiniSdk => {
  const axiosInstance = axios.create();

  const trackingSdk = new TrackingSdk(configuration, userSdk, axiosInstance);
  const authenticationSdk = new AuthenticationSdk(trackingSdk, configuration, userSdk, axiosInstance);
  addAxiosRequestInterceptors(axiosInstance, userSdk, authenticationSdk);

  return {
    trackingSdk: trackingSdk,
    storageSdk: userSdk.storageSdk,
  };
};

export const initBffSdkLibrary = async (): Promise<MiniSdk> => {
  const param = { basePath: API_URL ?? 'https://ivts-master-bff.dev.aws.vsct.fr' };
  const configuration = new Configuration(param);
  let osVersion = '';
  let deviceType = '';
  if (canUseNavigator()) {
    const uaParser = new UAParser(window.navigator.userAgent);
    const os = uaParser.getOS();
    osVersion = getTrackingOs(os.name, os.version);
    deviceType = getTrackingDeviceType(uaParser.getDevice().type);
  }
  const userSdk = new UserSdk(
    new User(
      new DeviceSettings(
        window.ivtsTag?.config?.market,
        'web',
        window.ivtsTag?.config?.clientId,
        ENV,
        osVersion,
        deviceType,
      ),
    ),
  );
  return initMiniSdk(configuration, userSdk);
};

const filterAsync = async <T>(
  array: T[],
  callbackfn: (value: T, index: number, array: T[]) => Promise<boolean>,
): Promise<T[]> => {
  const filterMap = await Promise.all(array.map(callbackfn));

  return array.filter((value, index) => filterMap[index]);
};

export const initDidomi = (trackingSdk: TrackingSdk): void => {
  window.didomiEventListeners = window.didomiEventListeners || [];
  window.didomiEventListeners.push({
    event: 'consent.changed',
    listener: async function () {
      const consentString = (await window.Didomi?.getUserStatus())?.consent_string;
      const vendorsWithoutPurposes = (await window.Didomi?.getUserStatus())?.vendors.consent.enabled;
      const vendorsWithPurposes =
        (await filterAsync(
          vendorsWithoutPurposes,
          async (vendor: string) => (await window.Didomi?.getUserConsentStatusForVendor(vendor)) === true,
        )) || [];
      await trackingSdk.updateConsentData(vendorsWithPurposes, consentString);
    },
  });
};

initBffSdkLibrary().then(sdk => {
  if (canUseDom()) {
    window.ivtsSdk = sdk; // expose sdk for external partners
    sdk.trackingSdk.init();
    initDidomi(sdk.trackingSdk);
    document.addEventListener('click', evt => sdk.trackingSdk.updateClickPosition(evt), true);
    sdk.trackingSdk.trackExternalPage(window.ivtsTag?.data).then((trackingInfo: TrackingResponseSuccess): void => {
      sdk.trackingSdk.updatePreviousPageName(trackingInfo.datalayer?.stringValues?.page_name);

      let timeout = 10;
      const poll = () => {
        setTimeout(() => {
          timeout--;
          if (window.utag && window.utag.view) {
            window.utag_data = trackingInfo.datalayer?.stringValues;
            window.utag.view(window.utag_data);
          } else if (timeout > 0) {
            poll();
          } else {
            console.error('Tealium is not loaded');
          }
        }, 500);
      };
      poll();
    });
  }
});
