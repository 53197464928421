import addToDate from 'date-fns/add';
import isPast from 'date-fns/isPast';
import Cookies from 'js-cookie';
import { app_version_value, bff_key_value } from './build';
import { UserAccountFound } from './generated';
import { AugmentedAxiosRequestConfig } from './interceptors';
import { VisitorType } from './enums';
import {
  ClidData,
  ClidDataMapper,
  EmailData,
  EmailDataMapper,
  GclidData,
  GclidDataMapper,
  VisitorTypeDataMapper,
} from './tracking';
import { AxiosRequestConfig } from 'axios';
import {
  app_version_header,
  attribution_campaign_header,
  attribution_clid_header,
  attribution_content_header,
  attribution_effi_id2_header,
  attribution_effi_id_header,
  attribution_gclid_header,
  attribution_id_compteur_header,
  attribution_medium_header,
  attribution_prex_header,
  attribution_referrer_header,
  attribution_source_header,
  attribution_term_header,
  bff_key_header,
  channel_header,
  client_app_id_header,
  consent_exempt_id_header,
  consent_id_header,
  consent_string_header,
  consent_vendors_header,
  device_class_header,
  device_os_version_header,
  effi_id,
  effi_id2,
  email_hidden_header,
  email_strong_header,
  email_stronger_header,
  env_header,
  gclid,
  id_compteur,
  locale_header,
  navigation_current_header,
  navigation_page_params,
  navigation_previous_header,
  post_back_id,
  prex,
  trace_id_header,
  visitor_type_header,
  wiz_campaign,
  wiz_clid,
  wiz_content,
  wiz_medium,
  wiz_source,
  wiz_term,
} from './userConstants';
import {
  attribution_clid_storage,
  attribution_gclid_storage,
  consent_id_storage,
  consent_string_storage,
  consent_vendors_storage,
  email_hidden_storage,
  email_strong_storage,
  email_stronger_storage,
  events_click_storage,
  events_push_storage,
  exempt_id_storage,
  navigation_previous_storage,
  StorageSdk,
  trace_id_storage,
  visitor_type_storage,
} from './storage';
import { v4 as uuid } from 'uuid';

// COOKIE KEYS
export const visitor_id_cookie = 'x-visitor-id';

// Les fonctions ci-dessous indiquent si on peut utiliser les objets du navigateur
// (dans certains contextes comme Node ou SSR ce n'est pas possible).
// Il y a plusieurs fonctions pour minimiser le couplage.

// Est-ce que l'objet 'window' est accessible ? (Node ? SSR ?)
export const canUseWindow = (): boolean => typeof window !== 'undefined';

// Est-ce que l'objet 'window.document' est accessible ? (Node ? SSR ?)
export const canUseDom = (): boolean =>
  canUseWindow() && typeof window.document !== 'undefined' && typeof window.document.createElement !== 'undefined';

// Est-ce que l'objet 'window.navigator' est accessible ? (Node ? SSR ?)
export const canUseNavigator = (): boolean => canUseWindow() && typeof window.navigator !== 'undefined';

// On souhaite renouveler le token 10 minutes avant l'expiration réelle.
const expiration_leeway = 60 * 10; // in seconds

export const attributionParamsToTrack: string[] = [
  wiz_source,
  wiz_medium,
  wiz_campaign,
  wiz_term,
  wiz_content,
  wiz_clid,
  gclid,
  post_back_id,
];

export class DeviceSettings {
  readonly clientMarket: string;
  readonly channel: string;
  readonly deviceId: string;
  readonly env: string;
  readonly osVersion?: string;
  readonly deviceClass?: string;

  constructor(
    clientMarket: string,
    channel: string,
    deviceId: string,
    env: string,
    osVersion?: string,
    deviceClass?: string,
  ) {
    this.clientMarket = clientMarket;
    this.channel = channel;
    this.deviceId = deviceId;
    this.env = env;
    this.osVersion = osVersion;
    this.deviceClass = deviceClass;
  }
}

export class User {
  deviceSettings: DeviceSettings;

  constructor(deviceSettings: DeviceSettings) {
    this.deviceSettings = deviceSettings;
  }
}

type RefreshTokenDataEvent =
  | {
      type: 'REFRESH_TOKEN_DATA';
      payload: UserAccountFound;
    }
  | {
      type: 'REFRESH_TOKEN_DATA_ERROR';
      payload: unknown;
    };

export type RefreshTokenDataEventListener = (event: RefreshTokenDataEvent) => void;

class RefreshTokenDataEventEmitter {
  #listeners: RefreshTokenDataEventListener[] = [];

  subscribe(listenerToAdd: RefreshTokenDataEventListener): void {
    this.#listeners.push(listenerToAdd);
  }

  unsubscribe(listenerToRemove: RefreshTokenDataEventListener): void {
    this.#listeners = this.#listeners.filter(listener => listener !== listenerToRemove);
  }

  dispatch(event: RefreshTokenDataEvent): void {
    this.#listeners.forEach(listener => listener(event));
  }
}

export class UserSdk {
  readonly user: User;
  readonly #refreshTokenDataEventEmitter = new RefreshTokenDataEventEmitter();
  readonly storageSdk: StorageSdk;
  #expiresAt?: Date;

  constructor(
    user: User,
    refreshTokenDataEventEmitter = new RefreshTokenDataEventEmitter(),
    storageSdk: StorageSdk = new StorageSdk(),
  ) {
    this.user = user;
    this.#refreshTokenDataEventEmitter = refreshTokenDataEventEmitter;
    this.storageSdk = storageSdk;
  }

  copyWithUser(user: User): UserSdk {
    const copy = new UserSdk(user, this.#refreshTokenDataEventEmitter, this.storageSdk);
    copy.#expiresAt = this.#expiresAt;
    return copy;
  }

  subscribeRefreshTokenDataEventListener(listenerToAdd: RefreshTokenDataEventListener): void {
    this.#refreshTokenDataEventEmitter.subscribe(listenerToAdd);
  }

  unsubscribeRefreshTokenDataEventListener = (listenerToRemove: RefreshTokenDataEventListener): void => {
    this.#refreshTokenDataEventEmitter.unsubscribe(listenerToRemove);
  };

  notifyRefreshTokenData = (data: UserAccountFound): void => {
    this.updateTokenData(data);
    this.#refreshTokenDataEventEmitter.dispatch({
      type: 'REFRESH_TOKEN_DATA',
      payload: data,
    });
  };

  notifyRefreshTokenError = (error: unknown): void => {
    this.resetTokenData();
    this.#refreshTokenDataEventEmitter.dispatch({
      type: 'REFRESH_TOKEN_DATA_ERROR',
      payload: error,
    });
  };

  isRefreshTokensNeeded = (): boolean => {
    return Boolean(this.#expiresAt && isPast(this.#expiresAt));
  };

  resetTokenData = (): void => {
    this.#expiresAt = undefined;
  };

  updateTokenData = (data: UserAccountFound): void => {
    this.#expiresAt = addToDate(new Date(), { seconds: data.expireIn - expiration_leeway });
  };

  updateDeviceSettings = (settings: DeviceSettings): User => {
    this.user.deviceSettings = settings;
    return this.user;
  };

  updateUserConsentData = (
    userConsentedVendors: string,
    userConsentString: string,
    consentId?: string,
    exemptId?: string,
  ): User => {
    this.storageSdk.localStorage()?.setItem(consent_vendors_storage, userConsentedVendors);
    this.storageSdk.localStorage()?.setItem(consent_string_storage, userConsentString);
    if (consentId) this.storageSdk.localStorage()?.setItem(consent_id_storage, consentId);
    if (exemptId) this.storageSdk.localStorage()?.setItem(exempt_id_storage, exemptId);
    return this.user;
  };

  getTraceId(): string {
    return this.storageSdk.sessionStorage()?.getItem(trace_id_storage) || '';
  }

  createVisitorId(): string {
    const PREFIX_VISITOR_ID_VALUE = 'fba';
    const uuidGenerated: string = uuid()
      .replace(/[^A-Za-z0-9\s]/g, '')
      .substr(0, 97); // due to adobe limit (100 characters)
    return `${PREFIX_VISITOR_ID_VALUE}${uuidGenerated}`;
  }

  getOrInitVisitorId(): string | undefined {
    if (canUseDom()) {
      const cookieVisitorId = Cookies.get(visitor_id_cookie);
      if (cookieVisitorId) {
        return cookieVisitorId;
      } else {
        const newVisitorId = this.createVisitorId();
        const expiryDate = new Date();
        expiryDate.setFullYear(expiryDate.getFullYear() + 1);
        Cookies.set(visitor_id_cookie, newVisitorId, { secure: true, path: '/', expires: expiryDate, sameSite: 'Lax' });
        return newVisitorId;
      }
    } else {
      return undefined;
    }
  }

  getConsentVendors(): string {
    return this.storageSdk.localStorage()?.getItem(consent_vendors_storage) || '';
  }

  getConsentString(): string {
    return this.storageSdk.localStorage()?.getItem(consent_string_storage) || '';
  }

  getConsentId(): string {
    return this.storageSdk.localStorage()?.getItem(consent_id_storage) || '';
  }

  getExemptId(): string {
    return this.storageSdk.localStorage()?.getItem(exempt_id_storage) || '';
  }

  getVisitorType(): string {
    return this.storageSdk.localStorage()?.getItem(visitor_type_storage) || VisitorType.NEW_USER;
  }

  getEmailHidden(): string | undefined {
    const emailHiddenRaw = this.storageSdk.localStorage()?.getItem(email_hidden_storage);
    if (emailHiddenRaw) {
      try {
        const emailHidden = EmailDataMapper.fromJSON(emailHiddenRaw);
        if (emailHidden.isExpired()) {
          this.storageSdk.localStorage()?.removeItem(email_hidden_storage);
        } else {
          return emailHidden.email;
        }
      } catch (e) {
        this.storageSdk.localStorage()?.removeItem(email_hidden_storage);
      }
    }
    return undefined;
  }

  setEmailHidden(emailHidden: string): void {
    this.storageSdk.localStorage()?.setItem(email_hidden_storage, EmailDataMapper.toJSON(new EmailData(emailHidden)));
  }

  getEmailStrong(): string | undefined {
    const emailStrongRaw = this.storageSdk.localStorage()?.getItem(email_strong_storage);
    if (emailStrongRaw) {
      try {
        const emailStrong = EmailDataMapper.fromJSON(emailStrongRaw);
        if (emailStrong.isExpired()) {
          this.storageSdk.localStorage()?.removeItem(email_strong_storage);
        } else {
          return emailStrong.email;
        }
      } catch (e) {
        this.storageSdk.localStorage()?.removeItem(email_strong_storage);
      }
    }
    return undefined;
  }

  setEmailStrong(emailStrong: string): void {
    this.storageSdk.localStorage()?.setItem(email_strong_storage, EmailDataMapper.toJSON(new EmailData(emailStrong)));
  }

  getEmailStronger(): string | undefined {
    const emailStrongerRaw = this.storageSdk.localStorage()?.getItem(email_stronger_storage);
    if (emailStrongerRaw) {
      try {
        const emailStronger = EmailDataMapper.fromJSON(emailStrongerRaw);
        if (emailStronger.isExpired()) {
          this.storageSdk.localStorage()?.removeItem(email_stronger_storage);
        } else {
          return emailStronger.email;
        }
      } catch (e) {
        this.storageSdk.localStorage()?.removeItem(email_stronger_storage);
      }
    }
    return undefined;
  }

  setEmailStronger(emailStronger: string): void {
    this.storageSdk
      .localStorage()
      ?.setItem(email_stronger_storage, EmailDataMapper.toJSON(new EmailData(emailStronger)));
  }

  getPreviousDatas(): string {
    const previousDatas = this.storageSdk.sessionStorage()?.getItem(navigation_previous_storage);
    if (previousDatas) {
      const { previousPageName, clickPosition } = JSON.parse(previousDatas);
      return clickPosition ? previousPageName + ':' + clickPosition : previousPageName;
    } else {
      return '';
    }
  }

  getDeferredEventsPush(): Set<string> {
    const eventsString = this.storageSdk.sessionStorage()?.getItem(events_push_storage);
    return new Set<string>(eventsString ? JSON.parse(eventsString) : new Set<string>());
  }

  getDeferredEventsClick(): Set<string> {
    const eventsString = this.storageSdk.sessionStorage()?.getItem(events_click_storage);
    return new Set<string>(eventsString ? JSON.parse(eventsString) : new Set<string>());
  }

  getHeaders = (): { [key: string]: string } => {
    this.getOrInitVisitorId();
    const headers: { [key: string]: string } = {};
    headers[channel_header] = this.user.deviceSettings?.channel as string;
    headers[client_app_id_header] = this.user.deviceSettings?.deviceId as string;
    headers[env_header] = this.user.deviceSettings?.env as string;
    headers[locale_header] = this.user.deviceSettings?.clientMarket as string;
    headers[email_hidden_header] = this.getEmailHidden() as string;
    headers[email_strong_header] = this.getEmailStrong() as string;
    headers[email_stronger_header] = this.getEmailStronger() as string;
    headers[consent_vendors_header] = this.getConsentVendors();
    headers[consent_string_header] = this.getConsentString();
    headers[consent_id_header] = this.getConsentId();
    headers[consent_exempt_id_header] = this.getExemptId();
    headers[app_version_header] = app_version_value;
    headers[bff_key_header] = bff_key_value;
    headers[device_os_version_header] = this.user.deviceSettings?.osVersion as string;
    headers[device_class_header] = this.user.deviceSettings?.deviceClass as string;
    headers[attribution_medium_header] = this.getParamFromUrl(wiz_medium);
    headers[attribution_source_header] = this.getParamFromUrl(wiz_source);
    headers[attribution_campaign_header] = this.getParamFromUrl(wiz_campaign);
    headers[attribution_term_header] = this.getParamFromUrl(wiz_term);
    headers[attribution_content_header] = this.getParamFromUrl(wiz_content);
    headers[attribution_prex_header] = this.getParamFromUrl(prex);
    headers[attribution_id_compteur_header] = this.getParamFromUrl(id_compteur);
    headers[attribution_effi_id_header] = this.getParamFromUrl(effi_id);
    headers[attribution_effi_id2_header] = this.getParamFromUrl(effi_id2);
    headers[attribution_clid_header] = this.getClid();
    headers[attribution_gclid_header] = this.getGclid();
    headers[attribution_referrer_header] = canUseDom() ? document.referrer : '';
    headers[navigation_previous_header] = this.getPreviousDatas();
    headers[navigation_current_header] = canUseWindow() ? window.location.pathname : '';
    headers[visitor_type_header] = VisitorTypeDataMapper.fromJSON(this.getVisitorType()).visitorType;
    headers[navigation_page_params] = this.getAttributionPageParams();
    headers[trace_id_header] = this.getTraceId();
    Object.keys(headers).forEach(key =>
      headers[key] === null || headers[key] === undefined || headers[key] === '' ? delete headers[key] : {},
    );
    return headers;
  };

  getAttributionPageParams = (): string =>
    canUseWindow() && this.isParamsTrackable(window.location.search) ? window.location.search : '';

  getParamFromUrl(param: string) {
    const paramValue = canUseWindow() ? new URLSearchParams(window.location.search).get(param) : null;
    return paramValue ? paramValue : '';
  }

  // return PostBackId if present, wizclid otherwise
  getClid(): string {
    // Deleting older Clid if existing (previous campaign) but new campaign has been launched
    if (canUseWindow() && this.isParamsTrackable(window.location.search)) {
      this.storageSdk.localStorage()?.removeItem(attribution_clid_storage);
    }
    const postBackId = canUseWindow() ? new URLSearchParams(window.location.search).get(post_back_id) : null;
    const wizClid = canUseWindow() ? new URLSearchParams(window.location.search).get(wiz_clid) : null;
    if (postBackId || wizClid) {
      this.storageSdk
        .localStorage()
        ?.setItem(
          attribution_clid_storage,
          ClidDataMapper.toJSON(
            new ClidData(new Date(), postBackId ? postBackId : wizClid || '').resetExpirationDate(),
          ),
        );
    }
    return ClidDataMapper.fromJSON(this.storageSdk.localStorage()?.getItem(attribution_clid_storage)).clid || '';
  }

  getGclid(): string {
    // Deleting older Gclid if existing (previous campaign) but new campaign has been launched
    if (canUseWindow() && this.isParamsTrackable(window.location.search)) {
      this.storageSdk.localStorage()?.removeItem(attribution_gclid_storage);
    }
    const paramValue = canUseWindow() ? new URLSearchParams(window.location.search).get(gclid) : null;
    if (paramValue) {
      this.storageSdk
        .localStorage()
        ?.setItem(
          attribution_gclid_storage,
          GclidDataMapper.toJSON(new GclidData(new Date(), paramValue).resetExpirationDate()),
        );
    }
    return GclidDataMapper.fromJSON(this.storageSdk.localStorage()?.getItem(attribution_gclid_storage)).gclid || '';
  }

  getBffHeader = (): string => {
    return this.getHeaders()[bff_key_header];
  };

  createAxiosOptions = (shouldRefreshTokens = true, options?: AxiosRequestConfig): AugmentedAxiosRequestConfig => {
    return {
      headers: { ...this.getHeaders(), ...options?.headers },
      ...(options?.params && { params: { ...options?.params } }),
      // Namespace to avoid potential conflicts with official Axios options
      ivts: {
        shouldRefreshTokens,
      },
    };
  };

  private isParamsTrackable = (params: string): boolean => attributionParamsToTrack.some(ele => params.includes(ele));
}
