/* tslint:disable */
/* eslint-disable */
/**
 * Invictus
 * Invictus Backend API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface TrackedEvent
 */
export interface TrackedEvent {
    /**
     * 
     * @type {string}
     * @memberof TrackedEvent
     */
    event: string;
    /**
     * 
     * @type {string}
     * @memberof TrackedEvent
     */
    eventType: TrackedEventEventTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TrackedEvent
     */
    pageName: string;
    /**
     * 
     * @type {number}
     * @memberof TrackedEvent
     */
    timestamp: number;
}

/**
    * @export
    * @enum {string}
    */
export enum TrackedEventEventTypeEnum {
    CLICK = 'CLICK',
    GESTURE = 'GESTURE',
    LIFECYCLE = 'LIFECYCLE',
    PUSH = 'PUSH',
    PAGE = 'PAGE'
}



