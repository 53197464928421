import { VisitorType } from '../enums';
import { addMinutes, addMonths, isAfter } from 'date-fns';

export abstract class ExpirableData {
  expirationDate: Date;

  constructor(expirationDate: Date = new Date()) {
    this.expirationDate = expirationDate;
  }

  isExpired = (): boolean => isAfter(new Date(), new Date(this.expirationDate));

  abstract resetExpirationDate(): void;
}

export class FirstVisitData extends ExpirableData {
  resetExpirationDate(): FirstVisitData {
    this.expirationDate = addMinutes(new Date(), 30);
    return this;
  }
}

export class FirstVisitDataMapper {
  static toJSON: (firstvisitData: FirstVisitData) => string = JSON.stringify;

  static fromJSON = (json: string | undefined | null): FirstVisitData => {
    const result = new FirstVisitData(new Date());
    result.resetExpirationDate();
    if (!json) {
      return result;
    }
    try {
      const parsed = JSON.parse(json);
      for (const prop in parsed) {
        (result as unknown as { [index: string]: unknown })[prop] = parsed[prop];
      }
      if (!result.expirationDate) result.resetExpirationDate();
      return result;
    } catch (e) {
      return result;
    }
  };
}

export class VisitorTypeData extends ExpirableData {
  visitorType: VisitorType;

  constructor(expirationDate: Date, visitorType: VisitorType) {
    super(expirationDate);
    this.visitorType = visitorType;
  }

  resetExpirationDate(): VisitorTypeData {
    this.expirationDate = addMonths(new Date(), 12);
    return this;
  }
}

export class VisitorTypeDataMapper {
  static toJSON: (visitorTypeData: VisitorTypeData) => string = JSON.stringify;

  static fromJSON = (json: string | undefined | null): VisitorTypeData => {
    const result = new VisitorTypeData(new Date(), VisitorType.NEW_USER);
    result.resetExpirationDate();
    if (!json) return result;
    try {
      return Object.assign(result, JSON.parse(json));
    } catch (e) {
      return new VisitorTypeData(new Date(), VisitorType.NEW_USER).resetExpirationDate();
    }
  };
}

export class EmailData extends ExpirableData {
  readonly email: string;

  constructor(email: string) {
    super();
    this.email = email;
    this.resetExpirationDate();
  }

  resetExpirationDate(): EmailData {
    this.expirationDate = addMonths(new Date(), 12);
    return this;
  }
}

export class EmailDataMapper {
  static toJSON: (emailData: EmailData) => string = JSON.stringify;

  static fromJSON = (json: string): EmailData => Object.assign(new EmailData(''), JSON.parse(json));
}

export class ClidData extends ExpirableData {
  clid: string;

  constructor(expirationDate: Date, clid: string) {
    super(expirationDate);
    this.clid = clid;
  }

  resetExpirationDate(): ClidData {
    this.expirationDate = addMinutes(new Date(), 30);
    return this;
  }
}

export class ClidDataMapper {
  static toJSON: (clidData: ClidData) => string = JSON.stringify;

  static fromJSON = (json: string | undefined | null): ClidData => {
    const result = new ClidData(new Date(), '');
    result.resetExpirationDate();
    if (!json) return result;
    try {
      return Object.assign(result, JSON.parse(json));
    } catch (e) {
      return new ClidData(new Date(), '').resetExpirationDate();
    }
  };
}

export class GclidData extends ExpirableData {
  gclid: string;

  constructor(expirationDate: Date, gclid: string) {
    super(expirationDate);
    this.gclid = gclid;
  }

  resetExpirationDate(): GclidData {
    this.expirationDate = addMinutes(new Date(), 30);
    return this;
  }
}

export class GclidDataMapper {
  static toJSON: (clidData: GclidData) => string = JSON.stringify;

  static fromJSON = (json: string | undefined | null): GclidData => {
    const result = new GclidData(new Date(), '');
    result.resetExpirationDate();
    if (!json) return result;
    try {
      return Object.assign(result, JSON.parse(json));
    } catch (e) {
      return new GclidData(new Date(), '').resetExpirationDate();
    }
  };
}
