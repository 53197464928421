import { canUseDom } from './user';

export class StorageSdk {
  localStorage = (): Storage | null => (canUseDom() ? localStorage : null);
  sessionStorage = (): Storage | null => (canUseDom() ? sessionStorage : null);
}

// STORAGE KEYS
export const email_hidden_storage = 'ivts-emailHidden';
export const email_strong_storage = 'ivts-emailStrong';
export const email_stronger_storage = 'ivts-emailStronger';
export const navigation_previous_storage = 'ivts-previousPageDatas';
export const visitor_type_storage = 'ivts-visitorType';
export const first_visit_storage = 'ivts-firstVisit';
export const event_to_consent_storage = 'ivts-event-pending';
export const events_push_storage = 'ivts-events-push';
export const events_click_storage = 'ivts-events-click';
export const trace_id_storage = 'ivts-trace-id';
export const consent_vendors_storage = 'ivts-con-v';
export const consent_vendors_filter_storage = 'ivts-con-v-filter';
export const consent_string_storage = 'ivts-con-s';
export const consent_id_storage = 'ivts-con-id';
export const exempt_id_storage = 'ivts-con-ex';
export const attribution_medium_storage = 'attribution-medium';
export const attribution_source_storage = 'attribution-source';
export const attribution_campaign_storage = 'attribution-campaign';
export const attribution_content_storage = 'attribution-content';
export const attribution_term_storage = 'attribution-term';
export const attribution_clid_storage = 'attribution-clid';
export const attribution_gclid_storage = 'attribution-gclid';
export const itineraryId_storage = 'itineraryId';
