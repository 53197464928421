export enum LegType {
  TRAIN = 'TRAIN',
  CAR = 'CAR',
  RER = 'RER',
  BUS = 'BUS',
  FOOT = 'FOOT',
  METRO = 'METRO',
  TRAMWAY = 'TRAMWAY',
  WAITING = 'WAITING',
  BOARDING = 'BOARDING',
  LANDING = 'LANDING',
  CROW_FLY = 'CROW_FLY',
  BUS_RAPID_TRANSIT = 'BUS_RAPID_TRANSIT',
  COACH = 'COACH',
  FUNICULAR = 'FUNICULAR',
  RAPID_TRANSIT = 'RAPID_TRANSIT',
  SHUTTLE = 'SHUTTLE',
  BIKE = 'BIKE',
  INTERCITY = 'INTERCITY',
  HST = 'HST',
  OTHER_TRAIN = 'OTHER_TRAIN',
  CAR_AGREEMENT = 'CAR_AGREEMENT',
  TER = 'TER',
  CARPOOL = 'CARPOOL',
  SHARED_CAR = 'SHARED_CAR',
  OTHER = 'OTHER',
  LOWCOST_BUS = 'LOWCOST_BUS',
}

export enum ModeType {
  DRIVING = 'DRIVING',
  WALKING = 'WALKING',
  BICYCLING = 'BICYCLING',
  TRANSIT = 'TRANSIT',
  OTHER = 'OTHER',
}

export enum Behaviour {
  UNRECOVERABLE = 'UNRECOVERABLE',
  RECOVERABLE = 'RECOVERABLE',
  DISPLAY_FIELD_ERROR = 'DISPLAY_FIELD_ERROR',
}

export enum TravelerIcon {
  TRAVELER = 'TRAVELER',
  PET = 'PET',
}

export enum CartError {
  CART_UNSUPPORTED_PRODUCT_MIX = '/booking/unsupported-product-mix',
}

export enum VisitorType {
  NEW_USER = '0',
  KNOWN_USER = '1',
}

export enum CartItemType {
  TRIP = 'TRIP',
  SUBSCRIPTION = 'SUBSCRIPTION',
  TICKET = 'TICKET',
}

export enum CartNextStep {
  CONTACT = 'CONTACT',
  PAYMENT = 'PAYMENT',
}

export enum CartPreReservationStatus {
  PRERESERVABLE = 'PRERESERVABLE',
  NOTPRERESERVABLE = 'NOT_PRERESERVABLE',
  PRERESERVED = 'PRERESERVED',
}

export enum TripState {
  ORIGIN_IS_DELETED = 'ORIGIN_IS_DELETED',
  DESTINATION_IS_DELETED = 'DESTINATION_IS_DELETED',
  DEPARTURE_TIME_IS_UPDATED = 'DEPARTURE_TIME_IS_UPDATED',
  ARRIVAL_TIME_IS_UPDATED = 'ARRIVAL_TIME_IS_UPDATED',
  DEPARTURE_IS_REPORTED = 'DEPARTURE_IS_REPORTED',
  ARRIVAL_IS_REPORTED = 'ARRIVAL_IS_REPORTED',
}

export enum SegmentState {
  IS_DELETED = 'IS_DELETED',
}
