import { AuthenticationControllerApi, Configuration, TokenRequest, UserAccountFound } from './generated';
import { Catch } from './catchError';
import { AbstractSdk, AbstractSdkConstructorParameters, ControllerApiConstructor } from './abstract';
import { UserSdk } from './user';
import axios, { AxiosInstance } from 'axios';
import { TrackingSdk } from './tracking';

export class AuthenticationSdk extends AbstractSdk<AuthenticationControllerApi> {
  readonly #loginUrl: string;
  readonly #logoutUrl: string;
  readonly trackingSdk: TrackingSdk;

  constructor(trackingSdk: TrackingSdk, ...[configuration, ...rest]: AbstractSdkConstructorParameters) {
    super(configuration, ...rest);

    this.#loginUrl = `${configuration.basePath}/api/v1/authenticate`;
    this.#logoutUrl = `${configuration.basePath}/api/v1/web/logout/front`;
    this.trackingSdk = trackingSdk;
  }

  static init(configuration: Configuration, userSdk: UserSdk, axiosInstanceParam?: AxiosInstance): AuthenticationSdk {
    const axiosInstance = axiosInstanceParam || axios.create();
    const trackingSdk = new TrackingSdk(configuration, userSdk, axiosInstance);

    return new this(trackingSdk, configuration, userSdk, axiosInstance);
  }

  protected getControllerApiConstructor(): ControllerApiConstructor<AuthenticationControllerApi> {
    return AuthenticationControllerApi;
  }

  loginUrlWithRedirectUri = (redirectUri: string, state?: string, loginHint?: string): string => {
    const params = new URLSearchParams();
    params.set('redirectUri', redirectUri);
    if (loginHint) {
      params.set('loginHint', loginHint);
    }
    if (state) {
      params.set('state', state);
    }
    return `${this.#loginUrl}?${params}`;
  };

  async logoutFront(redirectUri: string, state?: string): Promise<string> {
    if (this.userSdk.isRefreshTokensNeeded()) {
      await this.refreshTokensFromInterceptor();
    }
    const params = new URLSearchParams();
    params.set('redirectUri', redirectUri);
    if (state) {
      params.set('state', state);
    }
    return `${this.#logoutUrl}?${params}`;
  }

  @Catch<UserAccountFound>()
  async authenticate(code: string, redirectUri: string): Promise<UserAccountFound> {
    const request: TokenRequest = {
      code: code,
      redirectUri: redirectUri,
    };
    try {
      const response = await this.api.authenticate(
        this.userSdk.getBffHeader(),
        request,
        this.userSdk.createAxiosOptions(false),
      );
      const data = response.data;
      this.userSdk.updateTokenData(data);
      this.trackingSdk.updateEmails(data.emailHidden, data.emailStrong, data.emailStronger);
      return data;
    } catch (e) {
      this.userSdk.resetTokenData();
      throw e;
    }
  }

  @Catch<UserAccountFound>()
  async reauthenticate(): Promise<UserAccountFound> {
    try {
      const response = await this.api.webReauthenticate(
        this.userSdk.getBffHeader(),
        this.userSdk.createAxiosOptions(false),
      );
      const data = response.data;
      this.userSdk.updateTokenData(data);
      this.trackingSdk.updateEmails(data.emailHidden, data.emailStrong, data.emailStronger);
      return data;
    } catch (e) {
      this.userSdk.resetTokenData();
      throw e;
    }
  }

  async refreshTokensFromInterceptor(): Promise<void> {
    try {
      const response = await this.api.webReauthenticate(
        this.userSdk.getBffHeader(),
        this.userSdk.createAxiosOptions(false),
      );
      const data = response.data;
      this.userSdk.notifyRefreshTokenData(data);
      this.trackingSdk.updateEmails(data.emailHidden, data.emailStrong, data.emailStronger);
    } catch (e) {
      this.userSdk.notifyRefreshTokenError(e);
    }
  }

  @Catch<void>()
  async logoutBack(): Promise<void> {
    try {
      await this.api.webLogoutBack(this.userSdk.getBffHeader(), this.userSdk.createAxiosOptions());
    } finally {
      this.userSdk.resetTokenData();
    }
  }
}
