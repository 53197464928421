import { OutputErrorMessage } from './generated';
import { AxiosError } from 'axios';
import { SdkError, SdkErrorType } from './error';
import { websiteMonitor } from './instana';

/* eslint-disable @typescript-eslint/no-explicit-any */

const errorByType = (data?: OutputErrorMessage) => {
  const errorType = data?.type;
  switch (errorType) {
    case '/reauthentication/failed':
    case '/authentication/multiple-accounts':
      return 'authentificationFailed';
    default:
      return 'functionalError';
  }
};

const createSdkError = (error: any): SdkError => {
  let errorType: SdkErrorType = 'technicalError';
  let data: OutputErrorMessage | undefined = undefined;
  if (error.isAxiosError) {
    const axiosError: AxiosError<OutputErrorMessage> = error;
    errorType = 'serverError';
    if (axiosError.response) {
      data = error.response.data.behaviour ? error.response.data : undefined;
      const status = axiosError.response.status;
      if (status < 500) {
        errorType = errorByType(axiosError.response.data);
      }
    } else if (axiosError.message === 'Network Error') {
      errorType = 'networkError';
    }
  }
  return {
    isSdkError: true,
    type: errorType,
    data,
    displayedMessage: error.response?.data?.details,
  };
};

interface PromiseDescriptorValue<T> {
  (...args: any[]): Promise<T>;
}

/**
 * Error function Decorator Try/Catch
 *
 * @see https://www.typescriptlang.org/docs/handbook/decorators.html#method-decorators
 *
 * Usage: @Catch<ReturnType>()
 *
 * Example: @Catch<LongItineraryProposals | undefined>()
 */
export const Catch =
  <T>(): any =>
  (target: any, propertyKey: string, descriptor: any): TypedPropertyDescriptor<PromiseDescriptorValue<T>> => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const fn = descriptor.value!;
    descriptor.value = async function DescriptorValue(...args: any[]) {
      try {
        return await fn.apply(this, args);
      } catch (error) {
        // TODO: A modifier quand on sera passé sur AbortController
        if ((error as any)?.__CANCEL__) {
          return;
        }

        const sdkError = createSdkError(error);
        websiteMonitor(() => {
          // FIXME: le run validate du web plante si je ne met pas ça. On voit post livraison de Beta
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ineum('reportError', error, {
            meta: {
              category: 'ServerAlert',
              serviceName: propertyKey,
              ...(sdkError.data?.title && { errorCode: sdkError.data.title }),
              ...(sdkError.type && { type: sdkError.type }),
            },
          });
        });
        return Promise.reject(sdkError);
      }
    };
    return descriptor;
  };
/* eslint-enable */
