import {
  MetadataContent,
  TrackedEvent,
  TrackedEventEventTypeEnum,
  TrackedEventRequest,
  TrackingControllerApi,
  TrackingRequest,
  TrackingResponseSuccess,
  TrackingUpdateConsentRequest,
} from '../generated';
import { VisitorType } from '../enums';
import { AbstractSdk, AbstractSdkConstructorParameters, ControllerApiConstructor } from '../abstract';
import { AugmentedAxiosRequestConfig } from '../interceptors';
import { FirstVisitData, FirstVisitDataMapper, VisitorTypeData, VisitorTypeDataMapper } from './expirableData';
import { events_click_header, events_push_header } from '../userConstants';
import {
  consent_id_storage,
  consent_vendors_filter_storage,
  event_to_consent_storage,
  events_click_storage,
  events_push_storage,
  first_visit_storage,
  navigation_previous_storage,
  StorageSdk,
  visitor_type_storage,
} from '../storage';

interface PreviousDatas {
  previousPageName?: string;
  clickPosition?: string;
}

export enum PushEvent {
  PushTableauDesGares,
  PushItinerairesEnregistres,
  PushRetourExpress,
  PushFinalisationOptionSansEco,
  PushFinalisationOptionAvecEco,
}

export enum ExcludeTrackingFrontPageName {
  AjoutProduit = 'AjoutProduit',
}

export enum PageName {
  Interstitiel = 'Interstitiel',
  RechercheOD = 'RechercheOD',
  DevisAller = 'DevisAller',
  DevisRetour = 'DevisRetour',
  ResultatsItineraires = 'ResultatsItineraires',
  FicheTrajet = 'FicheTrajet',
  Panier = 'Panier',
  Services = 'Services',
  Coordonnees = 'Coordonnees',
  Paiement = 'Paiement',
  ConfirmationCommande = 'ConfirmationCommande',
  CancellationJourney = 'cancellationJourney',
  CancellationPassenger = 'cancellationPassenger',
  CancellationQuotation = 'cancellationQuotation',
  OptionsEnCours = 'OptionsEnCours',
  DetailOption = 'DetailOption',
  ConditionsTarifaires = 'ConditionsTarifaires',
  ModeDeRetrait = 'ModeDeRetrait',
  AccueilJustificatifs = 'AccueilJustificatifs',
  Ticketing = 'Ticketing',

  // Exchange
  EchangeSelectionTrajet = 'EchangeSelectionTrajet',
  EchangeSelectionPassager = 'EchangeSelectionPassager',
  EchangeMoteur = 'EchangeMoteur',
  EchangeDevisAller = 'EchangeDevisAller',
  EchangeDevisRetour = 'EchangeDevisRetour',
  EchangePanier = 'EchangePanier',
  EchangePaiement = 'EchangePaiement',
  ConfirmationEchange = 'ConfirmationEchange',

  // CCL*
  CCLAccueil = 'CCLAccueil',
  CCLCreationCompteEmail = 'CCLCreationCompteEmail',
  CCLCreationCompteMotdePasse = 'CCLCreationCompteMotdePasse',
  CCLCreationCompteCoordonnees = 'CCLCreationCompteCoordonnees',
  CCLCreationCompteConfirmation = 'CCLCreationCompteConfirmation',
  CCLCreationCompteLienExpire = 'CCLCreationCompteLienExpire',
  CCLCreationCompteLienActif = 'CCLCreationCompteLienActif',
  CCLCreationCompteLienInvalide = 'CCLCreationCompteLienInvalide',
  CCLCompagnons = 'CCLCompagnons',
  CCLMesInformations = 'CCLMesInformations',
  MesVoyages = 'MesVoyages',
  MesVoyagesPasses = 'MesVoyagesPasses',
  VoyageDeplieRetour = 'VoyageDeplieRetour',
  VoyageDeplieAller = 'VoyageDeplieAller',
  VoyageDeplieSejour = 'VoyageDeplieSejour',
  CCLMoyensPaiement = 'CCLMoyensPaiement',
  CCLModificationMoyensPaiement = 'CCLModificationMoyensPaiement',
  KiOuiConnexion = 'KiOuiConnexion',
  CCLCompagnonAjouter = 'CCLCompagnonAjouter',
  CCLCompagnonModifier = 'CCLCompagnonModifier',
  CCLCompagnonsCarte = 'CCLCompagnonsCarte',
  CCLCartesProgrammes = 'CCLCartesProgrammes',
  CCLAnimalAjouter = 'CCLAnimalAjouter',
  CCLGestionDeConsentement = 'CCLGestionDeConsentement',
  CCLNewslettersFrance = 'CCLNewslettersFrance',
  CCLNewslettersAutresPays = 'CCLNewslettersAutresPays',
  CCLCodeEntreprise = 'CCLCodeEntreprise',
  InfoTrafic = 'InfoTrafic',
  InfoTraficIdfDetails = 'InfoTraficIdfDetails',
}

export class TrackingSdk extends AbstractSdk<TrackingControllerApi> {
  readonly storageSdk: StorageSdk;

  constructor(...[configuration, ...rest]: AbstractSdkConstructorParameters) {
    super(configuration, ...rest);
    this.storageSdk = this.userSdk.storageSdk;
  }

  protected getControllerApiConstructor(): ControllerApiConstructor<TrackingControllerApi> {
    return TrackingControllerApi;
  }

  init(): void {
    this.initVisitorType();
    this.initPreviousPageDatas();
    this.initDeferredEvents();
  }

  initFirstVisit(): void {
    const rawFirstVisitDatas = this.storageSdk.localStorage()?.getItem(first_visit_storage);
    if (!rawFirstVisitDatas) {
      const newValue = new FirstVisitData(new Date()).resetExpirationDate();
      this.storageSdk.localStorage()?.setItem(first_visit_storage, FirstVisitDataMapper.toJSON(newValue));
    }
  }

  initVisitorType(): void {
    this.initFirstVisit();
    const firstVisitData: FirstVisitData = FirstVisitDataMapper.fromJSON(
      this.storageSdk.localStorage()?.getItem(first_visit_storage),
    );
    const visitorTypeData: VisitorTypeData = VisitorTypeDataMapper.fromJSON(
      this.storageSdk.localStorage()?.getItem(visitor_type_storage),
    );

    if (
      (visitorTypeData.visitorType === VisitorType.NEW_USER && firstVisitData.isExpired()) ||
      visitorTypeData.isExpired()
    ) {
      this.updateVisitorType(visitorTypeData, firstVisitData);
    } else {
      //rewrite object in case of corruption
      this.storageSdk.localStorage()?.setItem(first_visit_storage, FirstVisitDataMapper.toJSON(firstVisitData));
      this.storageSdk.localStorage()?.setItem(visitor_type_storage, VisitorTypeDataMapper.toJSON(visitorTypeData));
    }
  }

  private updateVisitorType(visitorTypeData: VisitorTypeData, firstVisitData: FirstVisitData) {
    if (visitorTypeData.isExpired()) {
      visitorTypeData.visitorType = VisitorType.NEW_USER;
      visitorTypeData.resetExpirationDate();
      firstVisitData = firstVisitData.resetExpirationDate();
    } else {
      if (firstVisitData.isExpired()) visitorTypeData.visitorType = VisitorType.KNOWN_USER;
      else visitorTypeData.visitorType = VisitorType.NEW_USER;
    }
    this.storageSdk.localStorage()?.setItem(first_visit_storage, FirstVisitDataMapper.toJSON(firstVisitData));
    this.storageSdk.localStorage()?.setItem(visitor_type_storage, VisitorTypeDataMapper.toJSON(visitorTypeData));
  }

  initPreviousPageDatas(): void {
    if (this.storageSdk.sessionStorage()?.getItem(navigation_previous_storage) === null) {
      const initDatas: PreviousDatas = {
        previousPageName: '',
        clickPosition: '',
      };
      this.storageSdk.sessionStorage()?.setItem(navigation_previous_storage, JSON.stringify(initDatas));
    }
  }

  private initDeferredEvents(): void {
    if (this.storageSdk.sessionStorage()?.getItem(events_push_storage) == null) {
      this.updateDeferredEventsPush(null);
    }
    if (this.storageSdk.sessionStorage()?.getItem(events_click_storage) == null) {
      this.updateDeferredEventsClick(null);
    }
  }

  private async getS2sVendors(): Promise<string[]> {
    try {
      const response = await this.api.getS2sVendors(
        this.userSdk.getBffHeader(),
        this.userSdk.createAxiosOptions(false),
      );
      const vendors = response.data.vendors;
      this.storageSdk.localStorage()?.setItem(consent_vendors_filter_storage, vendors.join());

      return vendors;
    } catch (error) {
      return this.storageSdk.localStorage()?.getItem(consent_vendors_filter_storage)?.split(',') ?? new Array('');
    }
  }

  private isConsentInitialized(): boolean {
    return this.storageSdk.localStorage()?.getItem(consent_id_storage) != null;
  }

  private isExemptionActive(userVendors: unknown[] | string): boolean {
    return !userVendors.includes('c:omniture-adobe-analytics');
  }

  private initializeConsentIds(isExemptionActive: boolean): [string, string] {
    const visitorId = this.userSdk.getOrInitVisitorId() ?? this.userSdk.createVisitorId();
    const newId = this.userSdk.createVisitorId();
    return isExemptionActive ? [newId, visitorId] : [visitorId, newId];
  }

  private updateExemptId(isExemptionActive: boolean): string | undefined {
    const wasExemptionActive = this.isExemptionActive(this.userSdk.getConsentVendors());
    return !wasExemptionActive && isExemptionActive ? this.userSdk.createVisitorId() : undefined;
  }

  private async filterVendors(userVendors: unknown[]): Promise<string> {
    if (userVendors.length < 1) {
      return '';
    } else {
      const s2sVendors = await this.getS2sVendors();
      const filteredVendors = Array.from(s2sVendors).filter((vendor: string) => {
        const vendorNumber = parseInt(vendor);
        return userVendors.includes(isNaN(vendorNumber) ? vendor : vendorNumber);
      });
      return filteredVendors.join();
    }
  }

  async updateConsentData(userVendors: unknown[], consentString: string): Promise<void> {
    const previousString = this.userSdk.getConsentString();
    if (consentString != previousString) {
      // Process consent IDs
      let consentId;
      let exemptId;
      const hasExemption = this.isExemptionActive(userVendors);
      if (this.isConsentInitialized()) {
        exemptId = this.updateExemptId(hasExemption);
      } else {
        [consentId, exemptId] = this.initializeConsentIds(hasExemption);
      }

      // Process vendors
      const filteredVendors = await this.filterVendors(userVendors);

      // Update storage
      this.userSdk.updateUserConsentData(filteredVendors, consentString, consentId, exemptId);

      // Update pending-consent events if any
      const eventToConsent = this.getDeferredEventToConsent();
      if (eventToConsent) {
        const response = await this.updateWithConsent(eventToConsent);
        if (response.ok) {
          this.replaceDeferredEventToConsent(null);
        }
      }
    }
  }

  /**
   * Iterate recursively through DOM to find an element to identify click position via data-attributes
   */
  findClickPosition(el: HTMLElement): string {
    let link: string | null = null;
    let block: string | null = null;
    let element: HTMLElement | null = el;

    do {
      if (link === null && element.dataset.rfrrlink !== undefined) {
        link = element.dataset.rfrrlink;
      }
      if (block === null && element.dataset.rfrrblock !== undefined) {
        block = element.dataset.rfrrblock;
      }

      element = element.parentElement;
    } while (element && (link == null || block == null));

    const rfrrs = [link, block].filter((item): item is string => !!item);
    if (rfrrs.length == 0) {
      return '';
    }

    return rfrrs.join(':');
  }

  updateClickPosition(evt: MouseEvent): void {
    const newClickPosition = this.findClickPosition(evt.target as HTMLElement);
    const previousDatas = this.storageSdk.sessionStorage()?.getItem(navigation_previous_storage) || '';
    const { previousPageName } = JSON.parse(previousDatas);
    const objDatas: PreviousDatas = {
      previousPageName: previousPageName,
      clickPosition: newClickPosition,
    };
    this.storageSdk.sessionStorage()?.setItem(navigation_previous_storage, JSON.stringify(objDatas));
  }

  updatePreviousPageName(pageName?: string): void {
    const previousDatas: PreviousDatas = {
      previousPageName: pageName,
      clickPosition: '',
    };
    this.storageSdk.sessionStorage()?.setItem(navigation_previous_storage, JSON.stringify(previousDatas));
  }

  updateEmails(emailHidden?: string, emailStrong?: string, emailStronger?: string): void {
    if (emailHidden?.trim() && this.userSdk.getEmailHidden() !== emailHidden)
      this.userSdk.setEmailHidden(emailHidden?.trim());
    if (emailStrong?.trim() && this.userSdk.getEmailStrong() !== emailStrong)
      this.userSdk.setEmailStrong(emailStrong?.trim());
    if (emailStronger?.trim() && this.userSdk.getEmailStronger() !== emailStronger)
      this.userSdk.setEmailStronger(emailStronger?.trim());
  }

  updateDatalayer(datalayer?: MetadataContent): void {
    try {
      const deferredEventToConsent = datalayer?.json;
      if (deferredEventToConsent) {
        this.replaceDeferredEventToConsent(deferredEventToConsent);
      }
      this.updateEmails(
        datalayer?.stringValues?.user_emails_hidden,
        datalayer?.stringValues?.user_emails_strong,
        datalayer?.stringValues?.user_emails_stronger,
      );
    } catch (error) {
      return;
    }
  }

  private updateDeferredEventsPush = (events: Set<string> | null): void => {
    this.storageSdk.sessionStorage()?.setItem(events_push_storage, JSON.stringify(Array.from(events ? events : [])));
  };

  private updateDeferredEventsClick = (events: Set<string> | null): void => {
    this.storageSdk.sessionStorage()?.setItem(events_click_storage, JSON.stringify(Array.from(events ? events : [])));
  };

  private async trackEventRequest(trackedEventRequest: TrackedEventRequest): Promise<TrackingResponseSuccess> {
    try {
      const response = await this.api.trackEvents(
        this.userSdk.getBffHeader(),
        trackedEventRequest,
        this.userSdk.createAxiosOptions(false),
      );
      return response.data;
    } catch (error) {
      return { ok: false };
    }
  }

  async trackEvent(
    eventName: string,
    eventType: TrackedEventEventTypeEnum,
    pageName: string,
    timestamp = Date.now(),
    allowDeferred = true,
  ): Promise<TrackingResponseSuccess> {
    try {
      if (eventType == TrackedEventEventTypeEnum.PUSH && allowDeferred) {
        this.deferEventPush(eventName);
        return { ok: true };
      } else if (eventType == TrackedEventEventTypeEnum.CLICK && allowDeferred) {
        this.deferEventClick(eventName);
        return { ok: true };
      } else {
        const trackedEvent: TrackedEvent = {
          event: eventName,
          eventType,
          pageName: pageName,
          timestamp: timestamp,
        };
        return this.trackEvents(trackedEvent);
      }
    } catch (err) {
      return { ok: false };
    }
  }

  private deferEventPush(pushName: string): void {
    const events = this.userSdk.getDeferredEventsPush();
    events.add(pushName);
    this.updateDeferredEventsPush(events);
  }

  private deferEventClick(clickName: string): void {
    const events = this.userSdk.getDeferredEventsClick();
    events.add(clickName);
    this.updateDeferredEventsClick(events);
  }

  private getDeferredEventToConsent(): string | null {
    return this.storageSdk.sessionStorage()?.getItem(event_to_consent_storage) ?? null;
  }

  private replaceDeferredEventToConsent = (event: string | null): void => {
    if (event) {
      this.storageSdk.sessionStorage()?.setItem(event_to_consent_storage, event);
    } else {
      this.storageSdk.sessionStorage()?.removeItem(event_to_consent_storage);
    }
  };

  private async trackEvents(...trackedEvents: TrackedEvent[]): Promise<TrackingResponseSuccess> {
    const trackedEventRequest: TrackedEventRequest = {
      events: trackedEvents,
    };
    return this.trackEventRequest(trackedEventRequest);
  }

  async genericTrackPage(trackingRequest: TrackingRequest): Promise<TrackingResponseSuccess> {
    try {
      const axiosOptions = this.userSdk.createAxiosOptions(false);
      this.addDeferredEventsHeaders(axiosOptions);
      const responseData = (
        await this.api.trackPage(trackingRequest.pageName, this.userSdk.getBffHeader(), trackingRequest, axiosOptions)
      ).data;
      this.updateDatalayer(responseData.datalayer);
      return responseData;
    } catch (error) {
      return { ok: false };
    }
  }

  async trackPage(pageName: string): Promise<TrackingResponseSuccess> {
    return await this.genericTrackPage({ pageName });
  }

  async trackPlanPage(pageName: string, region: string | null = null): Promise<TrackingResponseSuccess> {
    return await this.genericTrackPage({ pageName, pageRegion: region ? region : undefined });
  }

  async trackExternalPage({
    pageName,
    pageSeoType,
    pageSectionType,
  }: TrackingRequest): Promise<TrackingResponseSuccess> {
    return await this.genericTrackPage({
      pageName,
      pageSeoType,
      pageSectionType,
    });
  }

  async trackClick(clickId: string | undefined, pageName: string): Promise<TrackingResponseSuccess> {
    if (clickId === undefined) {
      return { ok: false };
    }
    try {
      const trackedEventRequest: TrackedEventRequest = {
        events: [
          {
            pageName: pageName,
            event: clickId,
            eventType: TrackedEventEventTypeEnum.CLICK,
            timestamp: Date.now(),
          },
        ],
      };
      const axiosOptions = this.userSdk.createAxiosOptions(false);
      const response = await this.api.trackEvents(this.userSdk.getBffHeader(), trackedEventRequest, axiosOptions);
      return response.data;
    } catch (error) {
      return { ok: false };
    }
  }

  async updateWithConsent(event: string): Promise<TrackingResponseSuccess> {
    try {
      const updateRequest: TrackingUpdateConsentRequest = { event: event };
      const axiosOptions = this.userSdk.createAxiosOptions(false);
      const responseData = (await this.api.updateWithConsent(this.userSdk.getBffHeader(), updateRequest, axiosOptions))
        .data;
      this.updateDatalayer(responseData.datalayer);
      return responseData;
    } catch (error) {
      return { ok: false };
    }
  }

  private addDeferredEventsHeaders(axiosOptions: AugmentedAxiosRequestConfig): void {
    const eventsPush = this.userSdk.getDeferredEventsPush();
    if (eventsPush.size > 0) {
      if (!axiosOptions.headers) {
        axiosOptions.headers = {};
      }
      axiosOptions.headers[events_push_header] = Array.from(eventsPush).join(',');
      this.updateDeferredEventsPush(null);
    }
    const eventsClick = this.userSdk.getDeferredEventsClick();
    if (eventsClick.size > 0) {
      if (!axiosOptions.headers) {
        axiosOptions.headers = {};
      }
      axiosOptions.headers[events_click_header] = Array.from(eventsClick).join(',');
      this.updateDeferredEventsClick(null);
    }
  }
}

export const getTrackingOs = (osName?: string, osVersion?: string): string | 'unknown' => {
  if (!osName) {
    return 'unknown';
  }

  return osVersion ? `${osName} (${osVersion})` : osName;
};

export const getTrackingDeviceType = (deviceType?: string): string => {
  switch (deviceType) {
    case undefined:
    case 'browser':
      return 'desktop';
    case 'mobile':
      return 'responsive';
    default:
      return deviceType;
  }
};
