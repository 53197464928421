/* tslint:disable */
/* eslint-disable */
/**
 * Invictus
 * Invictus Backend API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { LocationTrackerRequest } from '../model';
// @ts-ignore
import { TrackedEventRequest } from '../model';
// @ts-ignore
import { TrackingRequest } from '../model';
// @ts-ignore
import { TrackingResponseSuccess } from '../model';
// @ts-ignore
import { TrackingUpdateConsentRequest } from '../model';
// @ts-ignore
import { TrackingVendorsResponseSuccess } from '../model';
/**
 * TrackingControllerApi - axios parameter creator
 * @export
 */
export const TrackingControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get vendors consents to track server-side
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getS2sVendors: async (xBffKey: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            if (xBffKey === null || xBffKey === undefined) {
                throw new RequiredError('xBffKey','Required parameter xBffKey was null or undefined when calling getS2sVendors.');
            }
            const localVarPath = `/api/v1/t/vendors/s2s`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey !== undefined && xBffKey !== null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Track generic events
         * @param {string} xBffKey bff key header
         * @param {TrackedEventRequest} trackedEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackEvents: async (xBffKey: string, trackedEventRequest: TrackedEventRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            if (xBffKey === null || xBffKey === undefined) {
                throw new RequiredError('xBffKey','Required parameter xBffKey was null or undefined when calling trackEvents.');
            }
            // verify required parameter 'trackedEventRequest' is not null or undefined
            if (trackedEventRequest === null || trackedEventRequest === undefined) {
                throw new RequiredError('trackedEventRequest','Required parameter trackedEventRequest was null or undefined when calling trackEvents.');
            }
            const localVarPath = `/api/v1/t/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey !== undefined && xBffKey !== null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof trackedEventRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(trackedEventRequest !== undefined ? trackedEventRequest : {})
                : (trackedEventRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Track gesture events
         * @param {string} xBffKey bff key header
         * @param {TrackedEventRequest} trackedEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackGestureEvent: async (xBffKey: string, trackedEventRequest: TrackedEventRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            if (xBffKey === null || xBffKey === undefined) {
                throw new RequiredError('xBffKey','Required parameter xBffKey was null or undefined when calling trackGestureEvent.');
            }
            // verify required parameter 'trackedEventRequest' is not null or undefined
            if (trackedEventRequest === null || trackedEventRequest === undefined) {
                throw new RequiredError('trackedEventRequest','Required parameter trackedEventRequest was null or undefined when calling trackGestureEvent.');
            }
            const localVarPath = `/api/v1/t/events/gesture`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey !== undefined && xBffKey !== null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof trackedEventRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(trackedEventRequest !== undefined ? trackedEventRequest : {})
                : (trackedEventRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Track a generic page view
         * @param {string} pageName 
         * @param {string} xBffKey bff key header
         * @param {TrackingRequest} trackingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackPage: async (pageName: string, xBffKey: string, trackingRequest: TrackingRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageName' is not null or undefined
            if (pageName === null || pageName === undefined) {
                throw new RequiredError('pageName','Required parameter pageName was null or undefined when calling trackPage.');
            }
            // verify required parameter 'xBffKey' is not null or undefined
            if (xBffKey === null || xBffKey === undefined) {
                throw new RequiredError('xBffKey','Required parameter xBffKey was null or undefined when calling trackPage.');
            }
            // verify required parameter 'trackingRequest' is not null or undefined
            if (trackingRequest === null || trackingRequest === undefined) {
                throw new RequiredError('trackingRequest','Required parameter trackingRequest was null or undefined when calling trackPage.');
            }
            const localVarPath = `/api/v1/t/pages/{pageName}`
                .replace(`{${"pageName"}}`, encodeURIComponent(String(pageName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey !== undefined && xBffKey !== null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof trackingRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(trackingRequest !== undefined ? trackingRequest : {})
                : (trackingRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Track positions
         * @param {string} xBffKey bff key header
         * @param {LocationTrackerRequest} locationTrackerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackPositions: async (xBffKey: string, locationTrackerRequest: LocationTrackerRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            if (xBffKey === null || xBffKey === undefined) {
                throw new RequiredError('xBffKey','Required parameter xBffKey was null or undefined when calling trackPositions.');
            }
            // verify required parameter 'locationTrackerRequest' is not null or undefined
            if (locationTrackerRequest === null || locationTrackerRequest === undefined) {
                throw new RequiredError('locationTrackerRequest','Required parameter locationTrackerRequest was null or undefined when calling trackPositions.');
            }
            const localVarPath = `/api/v1/t/events/locations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey !== undefined && xBffKey !== null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof locationTrackerRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(locationTrackerRequest !== undefined ? locationTrackerRequest : {})
                : (locationTrackerRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update previously-tracked events with consents
         * @param {string} xBffKey bff key header
         * @param {TrackingUpdateConsentRequest} trackingUpdateConsentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithConsent: async (xBffKey: string, trackingUpdateConsentRequest: TrackingUpdateConsentRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xBffKey' is not null or undefined
            if (xBffKey === null || xBffKey === undefined) {
                throw new RequiredError('xBffKey','Required parameter xBffKey was null or undefined when calling updateWithConsent.');
            }
            // verify required parameter 'trackingUpdateConsentRequest' is not null or undefined
            if (trackingUpdateConsentRequest === null || trackingUpdateConsentRequest === undefined) {
                throw new RequiredError('trackingUpdateConsentRequest','Required parameter trackingUpdateConsentRequest was null or undefined when calling updateWithConsent.');
            }
            const localVarPath = `/api/v1/t/update-consent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xBffKey !== undefined && xBffKey !== null) {
                localVarHeaderParameter['x-bff-key'] = String(xBffKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof trackingUpdateConsentRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(trackingUpdateConsentRequest !== undefined ? trackingUpdateConsentRequest : {})
                : (trackingUpdateConsentRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrackingControllerApi - functional programming interface
 * @export
 */
export const TrackingControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get vendors consents to track server-side
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getS2sVendors(xBffKey: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrackingVendorsResponseSuccess>> {
            const localVarAxiosArgs = await TrackingControllerApiAxiosParamCreator(configuration).getS2sVendors(xBffKey, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Track generic events
         * @param {string} xBffKey bff key header
         * @param {TrackedEventRequest} trackedEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trackEvents(xBffKey: string, trackedEventRequest: TrackedEventRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrackingResponseSuccess>> {
            const localVarAxiosArgs = await TrackingControllerApiAxiosParamCreator(configuration).trackEvents(xBffKey, trackedEventRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Track gesture events
         * @param {string} xBffKey bff key header
         * @param {TrackedEventRequest} trackedEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trackGestureEvent(xBffKey: string, trackedEventRequest: TrackedEventRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrackingResponseSuccess>> {
            const localVarAxiosArgs = await TrackingControllerApiAxiosParamCreator(configuration).trackGestureEvent(xBffKey, trackedEventRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Track a generic page view
         * @param {string} pageName 
         * @param {string} xBffKey bff key header
         * @param {TrackingRequest} trackingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trackPage(pageName: string, xBffKey: string, trackingRequest: TrackingRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrackingResponseSuccess>> {
            const localVarAxiosArgs = await TrackingControllerApiAxiosParamCreator(configuration).trackPage(pageName, xBffKey, trackingRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Track positions
         * @param {string} xBffKey bff key header
         * @param {LocationTrackerRequest} locationTrackerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trackPositions(xBffKey: string, locationTrackerRequest: LocationTrackerRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrackingResponseSuccess>> {
            const localVarAxiosArgs = await TrackingControllerApiAxiosParamCreator(configuration).trackPositions(xBffKey, locationTrackerRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update previously-tracked events with consents
         * @param {string} xBffKey bff key header
         * @param {TrackingUpdateConsentRequest} trackingUpdateConsentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWithConsent(xBffKey: string, trackingUpdateConsentRequest: TrackingUpdateConsentRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrackingResponseSuccess>> {
            const localVarAxiosArgs = await TrackingControllerApiAxiosParamCreator(configuration).updateWithConsent(xBffKey, trackingUpdateConsentRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TrackingControllerApi - factory interface
 * @export
 */
export const TrackingControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get vendors consents to track server-side
         * @param {string} xBffKey bff key header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getS2sVendors(xBffKey: string, options?: any): AxiosPromise<TrackingVendorsResponseSuccess> {
            return TrackingControllerApiFp(configuration).getS2sVendors(xBffKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Track generic events
         * @param {string} xBffKey bff key header
         * @param {TrackedEventRequest} trackedEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackEvents(xBffKey: string, trackedEventRequest: TrackedEventRequest, options?: any): AxiosPromise<TrackingResponseSuccess> {
            return TrackingControllerApiFp(configuration).trackEvents(xBffKey, trackedEventRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Track gesture events
         * @param {string} xBffKey bff key header
         * @param {TrackedEventRequest} trackedEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackGestureEvent(xBffKey: string, trackedEventRequest: TrackedEventRequest, options?: any): AxiosPromise<TrackingResponseSuccess> {
            return TrackingControllerApiFp(configuration).trackGestureEvent(xBffKey, trackedEventRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Track a generic page view
         * @param {string} pageName 
         * @param {string} xBffKey bff key header
         * @param {TrackingRequest} trackingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackPage(pageName: string, xBffKey: string, trackingRequest: TrackingRequest, options?: any): AxiosPromise<TrackingResponseSuccess> {
            return TrackingControllerApiFp(configuration).trackPage(pageName, xBffKey, trackingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Track positions
         * @param {string} xBffKey bff key header
         * @param {LocationTrackerRequest} locationTrackerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackPositions(xBffKey: string, locationTrackerRequest: LocationTrackerRequest, options?: any): AxiosPromise<TrackingResponseSuccess> {
            return TrackingControllerApiFp(configuration).trackPositions(xBffKey, locationTrackerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update previously-tracked events with consents
         * @param {string} xBffKey bff key header
         * @param {TrackingUpdateConsentRequest} trackingUpdateConsentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWithConsent(xBffKey: string, trackingUpdateConsentRequest: TrackingUpdateConsentRequest, options?: any): AxiosPromise<TrackingResponseSuccess> {
            return TrackingControllerApiFp(configuration).updateWithConsent(xBffKey, trackingUpdateConsentRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TrackingControllerApi - object-oriented interface
 * @export
 * @class TrackingControllerApi
 * @extends {BaseAPI}
 */
export class TrackingControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get vendors consents to track server-side
     * @param {string} xBffKey bff key header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackingControllerApi
     */
    public getS2sVendors(xBffKey: string, options?: any) {
        return TrackingControllerApiFp(this.configuration).getS2sVendors(xBffKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Track generic events
     * @param {string} xBffKey bff key header
     * @param {TrackedEventRequest} trackedEventRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackingControllerApi
     */
    public trackEvents(xBffKey: string, trackedEventRequest: TrackedEventRequest, options?: any) {
        return TrackingControllerApiFp(this.configuration).trackEvents(xBffKey, trackedEventRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Track gesture events
     * @param {string} xBffKey bff key header
     * @param {TrackedEventRequest} trackedEventRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackingControllerApi
     */
    public trackGestureEvent(xBffKey: string, trackedEventRequest: TrackedEventRequest, options?: any) {
        return TrackingControllerApiFp(this.configuration).trackGestureEvent(xBffKey, trackedEventRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Track a generic page view
     * @param {string} pageName 
     * @param {string} xBffKey bff key header
     * @param {TrackingRequest} trackingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackingControllerApi
     */
    public trackPage(pageName: string, xBffKey: string, trackingRequest: TrackingRequest, options?: any) {
        return TrackingControllerApiFp(this.configuration).trackPage(pageName, xBffKey, trackingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Track positions
     * @param {string} xBffKey bff key header
     * @param {LocationTrackerRequest} locationTrackerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackingControllerApi
     */
    public trackPositions(xBffKey: string, locationTrackerRequest: LocationTrackerRequest, options?: any) {
        return TrackingControllerApiFp(this.configuration).trackPositions(xBffKey, locationTrackerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update previously-tracked events with consents
     * @param {string} xBffKey bff key header
     * @param {TrackingUpdateConsentRequest} trackingUpdateConsentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackingControllerApi
     */
    public updateWithConsent(xBffKey: string, trackingUpdateConsentRequest: TrackingUpdateConsentRequest, options?: any) {
        return TrackingControllerApiFp(this.configuration).updateWithConsent(xBffKey, trackingUpdateConsentRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
